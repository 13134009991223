<template>
  <div class="flex flex-col h-full">
    <h6 class="mb-10">Verify with Bank Statement</h6>

    <div class="flex-grow flex flex-col">
      <div class="bg-corporate-900 mb-10 p-5 rounded-sm">
        <h3 class="text-10 mb-4 font-thin">
          To verify kindly select your {{ isEmployed ? 'salary' : 'income' }} bank account
        </h3>

        <div>
          <form-group
            v-model="form.data.bank.value"
            value-type="name"
            type="bank"
          >
            Select Bank
          </form-group>

          <SelectionOption
            v-if="canUseMbs"
            :is-selected="isMbsSelected"
            :is-verified="isMbsVerified"
            :disabled="form.loading"
            @click.prevent="selectOption('mbs')"
          >
            <template #title>
              Verify with MBS
            </template>
            <template #body>
              Provide basic bank information to continue.
            </template>
          </SelectionOption>

          <SelectionOption
            v-if="canUseMono"
            :is-selected="isMonoSelected"
            :is-verified="isMonoVerified"
            :disabled="form.loading"
            @click.prevent="selectOption('mono')"
          >
            <template #title>
              Verify with Mono
            </template>
            <template #body>
              Provide basic bank information to continue.
            </template>
          </SelectionOption>

          <SelectionOption
            v-if="canUseOkra"
            :is-selected="isOkraSelected"
            :is-verified="isOkraVerified"
            :disabled="form.loading"
            @click.prevent="selectOption('okra')"
          >
            <template #title>
              Verify with Okra
            </template>
            <template #body>
              Provide basic bank information to continue.
            </template>
          </SelectionOption>

          <SelectionOption
            v-if="canUseUssd"
            :is-gt-bank="true"
            :is-selected="isUssdSelected"
            :is-verified="isUssdVerified"
            :disabled="form.loading"
            @click.prevent="selectOption('ussd')"
          >
            <template #title>
              Verify with USSD
            </template>
            <template #body>
              Use USSD Banking to send your bank statement.
            </template>
          </SelectionOption>

          <SelectionOption
            v-if="canUseMobile"
            :is-gt-bank="true"
            :is-selected="isMobileSelected"
            :is-verified="isMobileVerified"
            :disabled="form.loading"
            @click.prevent="selectOption('mobile')"
          >
            <template #title>
              Verify with Mobile App
            </template>
            <template #body>
              Use Mobile Banking App to send your bank statement.
            </template>
          </SelectionOption>

          <SelectionOption
            v-if="canUseManualUpload"
            :is-selected="isManualUploadSelected"
            :is-verified="isManualUploadVerified"
            :disabled="form.loading"
            @click.prevent="selectOption('manual-upload')"
          >
            <template #title>
              Verify manually with Bank Statement
            </template>
            <template #body>
              Provide a 6 months bank statement instead.
            </template>
          </SelectionOption>
        </div>
      </div>

      <div class="flex gap-4 mt-auto">
        <button
          type="button"
          class="btn btn-gray-soft btn-md relative"
          :disabled="form.loading"
          @click="back"
        >
          <span>Back</span>
          <i class="absolute inline-flex left-20px top-1/2" style="transform: translateY(-50%);">
            <ion-icon name="arrow-back-outline" />
          </i>
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md mt-auto relative w-full"
          :disabled="form.loading || !canProceed"
          @click="useSelectedOption"
        >
          <sm-loader v-if="form.loading" class="sm-loader-white" />
          <span v-else>Next</span>
          <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
            <ion-icon name="arrow-forward-outline" />
          </i>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'BankAccountVerificationSelection',
  props: {
    bank: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SelectionOption: () => import('./SelectionOption'),
  },
  data() {
    return {
      form: this.$options.basicForm([
        'bank',
        'verification_option',
      ]),
    };
  },
  computed: {
    ...mapState(["banks", "mbsBanks", "okraBanks"]),
    availableVerificationOptions() {
      const availableVerificationOptions = [];

      if (this.canUseManualUpload) availableVerificationOptions.push('manual-upload');
      if (this.canUseMbs) availableVerificationOptions.push('mbs');
      if (this.canUseMobile) availableVerificationOptions.push('mobile');
      if (this.canUseMono) availableVerificationOptions.push('mono');
      if (this.canUseOkra) availableVerificationOptions.push('okra');
      if (this.canUseUssd) availableVerificationOptions.push('ussd');

      return availableVerificationOptions;
    },
    canProceed() {
      return this.form.data.verification_option.value;
    },
    canUseManualUpload() {
      return !!this.selectedBank
        && !this.isMbsVerified
        && !this.isMonoVerified
        && !this.isOkraVerified
        && !this.isUssdVerified
        && !this.isMobileVerified;
    },
    canUseMbs() {
      return this.mbsBanks.includes(this.selectedBank)
        && (!this.isMonoVerified && !this.isOkraVerified && !this.isManualUploadVerified);
    },
    canUseMobile() {
      return this.selectedBank === 'Guaranty Trust Bank'
        && (!this.isUssdVerified && !this.isManualUploadVerified);
    },
    canUseMono() {
      return (this.canUseMbs || this.canUseOkra)
        && (!this.isMbsVerified && !this.isOkraVerified && !this.isManualUploadVerified);
    },
    canUseOkra() {
      return this.okraBanks.includes(this.selectedBank)
      && (!this.isMonoVerified && !this.isMbsVerified && !this.isManualUploadVerified);
    },
    canUseUssd() {
      return this.selectedBank === 'Guaranty Trust Bank'
        && (!this.isMobileVerified && !this.isManualUploadVerified);
    },
    component() {
      switch (this.verificationOption) {
        case 'manual-upload':
          return 'manual-upload-form';
        case 'mbs':
          return 'mbs-form';
        case 'mobile':
          return 'mobile-form';
        case 'ussd':
          return 'ussd-form';
        default:
          return null;
      }
    },
    isBankStatementUploaded() {
      return this.user?.documents?.find(doc => doc.url === 'bank_statement');
    },
    isEmployed() {
      return this.user?.profile?.employment_status === 'employed';
    },
    isManualUploadSelected() {
      return this.verificationOption === 'manual-upload';
    },
    isManualUploadVerified() {
      return this.isBankStatementUploaded
        && !this.monoVerified
        && !this.mbsVerified
        && !this.okraVerified
        && !this.ussdVerified
        && !this.mobileVerified;
    },
    isMbsSelected() {
      return this.verificationOption === 'mbs';
    },
    isMbsVerified() {
      return !!this.user?.documents?.find(doc => doc.url === 'mbs');
    },
    isMobileSelected() {
      return this.verificationOption === 'mobile';
    },
    isMobileVerified() {
      return !!this.user?.documents?.find(doc => doc.url === 'mobile');
    },
    isMonoSelected() {
      return this.verificationOption === 'mono';
    },
    isMonoVerified() {
      return !!this.user?.documents?.find(doc => doc.url === 'mono');
    },
    isOkraSelected() {
      return this.verificationOption === 'okra';
    },
    isOkraVerified() {
      return !!this.user?.documents?.find(doc => doc.url === 'okra');
    },
    isUssdSelected() {
      return this.verificationOption === 'ussd';
    },
    isUssdVerified() {
      return !!this.user?.documents?.find(doc => doc.url === 'ussd');
    },
    selectedBank() {
      return this.form.data.bank.value;
    },
    verificationOption() {
      return this.form.data.verification_option.value;
    },
  },
  mounted() {
    this.mapData();
  },
  watch: {
    availableVerificationOptions() {
      this.$emit('update-bank-account-verification-options', this.availableVerificationOptions);
    },
    isLoading(status) {
      this.form.setLoading(status);
    }
  },
  methods: {
    back() {
      this.$emit('back-to-main-selection');
    },
    emitVerifyMonoEvent(data) {
      this.$emit('verify-mono', data);
    },
    emitVerifyOkraEvent(data) {
      this.$emit('verify-okra', data);
    },
    mapData() {
      this.form.setLoading(this.isLoading);
      this.form.data.bank.value = this.bank;
    },
    selectOption(option) {
      this.form.data.verification_option.value = option;
    },
    setLoading(status) {
      this.form.setLoading(status);
    },
    useSelectedOption() {
      const bank = this.getFormValue('bank');
      const option = this.getFormValue('verification_option');
      this.$emit('select-option', { bank, option });
    }
  },
};
</script>
